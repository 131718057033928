import { createRouter, createWebHistory } from "vue-router";

let routes = [
  // Megalos Admin Routes
  {
    path: "/admin",
    redirect: { name: "DashboardAdmin" },
    meta: {
      authAdmin: true,
      title: "home",
    },
    component: () => import("../layouts/LayoutsAdmin.vue"),
    children: [
      {
        path: "/admin/dashboard",
        name: "DashboardAdmin",
        meta: {
          title: "dashboard",
        },
        component: () => import("../views/Admin/DashboardAdminPage.vue"),
      },
      {
        name: "Data User",
        path: "/admin/data-user",
        meta: {
          title: "data-user",
        },
        component: () => import("../views/Admin/DataUserPage.vue"),
      },
      {
        name: "Data Customer",
        path: "/admin/data-customer",
        meta: {
          title: "data-customer",
        },
        component: () => import("../views/Admin/DataCustPage.vue"),
      },
      {
        name: "Inheritance",
        path: "/admin/inheritance",
        meta: {
          title: "inheritance",
        },
        component: () => import("../views/Admin/InheritancePage.vue"),
      },
    ],
  },

  {
    path: "/",
    redirect: { name: "Dashboard" },
    meta: {
      authCafe: true,
    },
    component: () => import("../layouts/LayoutsParent.vue"),
    children: [
      {
        path: "/dashboard",
        name: "Dashboard",
        meta: {
          title: "dashboard",
        },
        component: () => import("../views/Monitoring/DashboardPage.vue"),
      },
      {
        path: "/data-cafe/:id/:title",
        name: `Data Cafe`,
        meta: {
          title: "data-cafe",
        },
        component: () => import("../views/Monitoring/MonitoringPage.vue"),
      },
    ],
  },

  // sign-in route
  {
    path: "/sign-in",
    meta: {
      guestPage: true,
    },
    exact: true,
    component: () => import("../views/auth/SignInView.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () => import("../views/auth/SignInView.vue"),
      },
    ],
  },
];

function addLayoutToRoute(route, parentLayout = "default") {
  route.meta = route.meta || {};
  route.meta.layout = parentLayout;

  if (route.children) {
    route.children = route.children.map((childRoute) =>
      addLayoutToRoute(childRoute, route.meta.layout)
    );
  }
  return route;
}

routes = routes.map((route) => addLayoutToRoute(route));

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
    return {
      x: 0,
      y: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach((to, from, next) => {
  const dataUser = JSON.parse(localStorage.getItem("dataUser"));
  const noSession = dataUser === null || dataUser === "undefined";

  document.title = to.name
    ? `${to.name} - Megalos Cafe`
    : "Megalos - Solution Technologies";

  if (to.matched.some((record) => record.meta.authAdmin)) {
    if (noSession) {
      next({
        name: "sign-in",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.authCafe)) {
    if (noSession) {
      next({
        name: "sign-in",
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guestPage)) {
    if (noSession) {
      next();
    } else {
      const role_name = dataUser.role_name;
      if (role_name === "admin") {
        next({
          name: "DashboardAdmin",
        });
      } else if (role_name === "customer") {
        next({
          name: "Dashboard",
        });
      }
    }
  } else {
    next();
  }
});

export default router;
