import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// assets
import Antd from "ant-design-vue";
import VueSweetalert2 from "vue-sweetalert2";

// notify
import Notifications from "@kyvg/vue3-notification";
import Notify from "@/boot/notify.js";
import axiosInstance from "./boot/axios";

import "ant-design-vue/dist/reset.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "./index.css";

// fa icon
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

const app = createApp(App);
app.component("font-awesome-icon", FontAwesomeIcon);
app.config.globalProperties.$axios = axiosInstance;

library.add(fas);

app
  .use(Antd)
  .use(Notifications)

  .use(Notify)
  .use(router)
  .use(VueSweetalert2)

  .mount("#app");
